import React, { useState, useEffect } from 'react';
import './App.css';
import { IoMdArrowRoundForward } from "react-icons/io";
const styles = {

  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    fontSize: '16px',
  },
  button: {
    padding: '10px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  success: {
    color: 'green',
    marginTop: '10px',
  },
  error: {
    color: 'red',
    marginTop: '10px',
  },
};

const TextSubmit = () => {
  const [text, setText] = useState('');
  const [email, setEmail] = useState(''); // New state for email
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      setResponse(null);
      return;
    }



    // Prepare the data to send
    const data = { email };

    try {
      const res = await fetch('/server.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await res.json();
      console.log(result);
      if (result.status === 'success') {
        console.log(result);
        setResponse(result.message);
        setError(null);
        setText(''); // Clear the input
      } else {
        setError(result.message);
        setResponse(null);
        console.log(result)
      }
    } catch (err) {
      setError('An error occurred while submitting the data.');
      setResponse(null);
      console.error(err);
    }
  };
  // Donot check on every change
  const handleChange = async (e) =>{
    e.preventDefault();
    setError(null);
    setText('');
    setResponse(null);
  }

  return (
    <div>
      <form onSubmit={handleSubmit} onChange={handleChange}style={styles.form}>
        <div className="flex-container">
          <input className="email" name="email" type="text"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}></input>
          <button type="submit" className='emailsubmit'><IoMdArrowRoundForward />
          </button>

        </div>
      </form>

      {response && <div style={styles.success}>{response}</div>}
      {error && <div style={styles.error}>{error}</div>}

    </div>
  );
};



function App() {

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light'); // Set initial theme

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme); // Store the current theme in localStorage
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
  };


  return (
    <div className="App">
      <header className="App-header">
        <p className='logo'>LOCKINK</p>
        <div onClick={toggleTheme}>
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 231.93 377.31">
            <path className="latch" d="m27.79,87.86C27.97,39.17,67.58-.17,116.28,0c48.67.18,88.06,31.8,87.86,88.48-.08,22.64-8.69,43.26-22.78,58.82-2.14-12.29-7.64-23.37-15.49-32.32,4.27-7.92,6.7-16.98,6.73-26.6.11-31.28-25.15-56.74-56.44-56.84-31.28-.12-56.73,25.14-56.84,56.43-.04,9.74,2.38,18.9,6.68,26.91t0,.01c-7.91,8.96-13.42,20.06-15.58,32.27-14.15-15.71-22.73-36.51-22.64-59.3Z"
            />
            <path className="lock" d="m169.65,158.64c-8.92,20.84-29.6,35.43-53.69,35.43s-44.77-14.59-53.69-35.43C25.26,178,0,216.78,0,261.45c0,61.01,47.11,111.01,106.93,115.61v-1.91c-1.17-43.64-33.04-65.83-33.04-65.83l20.62-50.4,21.38.68h.14l21.38-.68,20.62,50.4s-32.72,22.78-33.07,67.58h-.01v.16c59.85-4.58,106.98-54.58,106.98-115.61,0-44.68-25.26-83.45-62.28-102.81Zm-20.77,74.07l-5.99,13.25h-53.84l-5.99-13.25-.66-1.52,33.57-.04,33.57.04-.66,1.52Z"
            />
            <path fill="red" d="m112.05,338.31v-38.93l-2.03-1.12c-2.85-1.43-5.17-4.05-6.52-7.35-1.05-2.48-1.05-3.08-.07-5.93.68-1.8,1.72-3.9,2.47-4.65,1.8-2.1,6.83-4.27,9.9-4.27,5.4,0,12.07,4.8,13.2,9.45,1.12,4.28-2.02,10.43-6.52,12.68l-2.18,1.2v77.93h-8.25v-39Zm0,0"
            />
          </svg>
        </div>
        <p>
          Coming Soon..
        </p>
        <span>Enter email to remain informed about progress</span>
        <TextSubmit />

      </header>
    </div>
  );
}

export default App;
